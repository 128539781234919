export const COMMUNITY_USER_FEED_STATE_FRAGMENT = `
  fragment communityUserFeedStateFragment on CommunityUser {
    uid
    lastViewedFeeds
    feedStates {
      uid
      lastViewedFeed
      feedEntity {
        uid
      }
    }
  }
`;
